import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom"
import { Avatar, Button, Input } from "@mui/joy";

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { server } from "../../App";
import { CenteredLoader } from "../../utils/Utils";


export const FactionView = () => {
    const { faction } = useParams();
    const navigate = useNavigate();
    const [factionData, setFaction] = useState<any>();
    const [isLoading, setLoading] = useState(false);
    
    useEffect(() => {
        const load = async () => {
            setLoading(true);
            const response = await fetch(`${server}/faction/${faction}`, {
                method: 'GET',
                mode: 'cors',
            });
            const companyData = await response.json();

            console.log(companyData);

            if (companyData === null) {
                navigate('/factions');
            }

            const sortedMembers = [...companyData.member].sort((a, b) => b.faction_grade - a.faction_grade);
            companyData.member = sortedMembers;

            setFaction(companyData);
            setLoading(false);
        }

        load();
    }, [navigate]);

    return (
        <>
            {!isLoading ? (
                <>
                    {factionData ? (
                        <div className="flex flex-col min-h-screen bg-gray-900 text-gray-200">
                            <div className="mt-10 w-full max-w-4xl mx-auto px-6 py-8 space-y-10">
                                {/* Header */}
                                <div className="flex items-center gap-6 bg-gray-800 p-6 rounded-lg shadow-lg">
                                    <Avatar className="h-24 w-24 bg-blue-800 flex items-center justify-center">
                                        <BusinessCenterIcon className="text-white text-3xl" />
                                    </Avatar>
                                    <div className="grid gap-2">
                                        <h2 className="text-4xl font-extrabold text-white">{factionData.faction.fullname}</h2>
                                        <p className="text-gray-400 text-lg">Mitglieder: {`${factionData.member.length}/${factionData.faction.maxMember}`}</p>
                                    </div>
                                </div>

                                {/* Mitglieder */}
                                <div className="space-y-6">
                                    <h2 className="text-3xl font-semibold border-b border-gray-700 pb-2">Mitglieder</h2>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                        {factionData.member &&
                                            factionData.member.map((player: any) => (
                                                <Player
                                                    key={player.uuid}
                                                    username={player.player_name}
                                                    rank={player.faction_grade}
                                                    uuid={player.uuid}
                                                    isLeader={player.isLeader}
                                                />
                                            ))}
                                    </div>
                                </div>

                                {/* Gangwar Zones */}
                                {factionData.gangwarZones && factionData.gangwarZones.length >= 1 && (
                                    <div className="space-y-6">
                                        <h2 className="text-3xl font-semibold border-b border-gray-700 pb-2">Gangwar-Gebiete</h2>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                            {factionData.gangwarZones.map((zone: any) => (
                                                <GangwarZone
                                                    key={zone.zone}
                                                    name={zone.zone}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="flex items-center justify-center h-screen bg-gray-900">
                            <CenteredLoader />
                        </div>
                    )}
                </>
            ) : (
                <div className="flex items-center justify-center h-screen bg-gray-900">
                    <CenteredLoader />
                </div>
            )}
        </>
    )
}

const Player = ({ username, uuid, rank, isLeader }: any) => {
    return (
        <div className="flex gap-4 items-center rounded-lg bg-gray-800 shadow-lg p-6 transform hover:scale-105 hover:bg-gray-700 transition-transform">
            <Avatar className="w-16 h-16 border-2 border-gray-600 bg-gray-900">
                <img
                    src={`https://crafatar.com/avatars/${uuid}`}
                    alt={`Avatar of ${uuid}`}
                    className="rounded-full"
                />
            </Avatar>
            <div className="grid gap-1">
                <h3 className="font-bold text-xl text-white">{username}</h3>
                <p className="text-sm text-gray-400">Rang {rank} {isLeader === 1 && (' (Leader)')}</p>
            </div>
        </div>
    )
}

const GangwarZone = ({ name }: any) => {
    return (
        <div className="flex gap-4 items-center rounded-lg bg-gray-800 shadow-lg p-6 transform hover:scale-105 hover:bg-gray-700 transition-transform">
            <Avatar className="w-16 h-16 border-2 border-gray-600 bg-gray-900">
                <ApartmentIcon className="text-blue-500 text-3xl" />
            </Avatar>
            <div className="grid gap-1">
                <h3 className="font-bold text-xl text-white">{name}</h3>
            </div>
        </div>
    )
}
