import { Link } from "react-router-dom";
import { Box, Typography } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel'; // For Impressum
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'; // For Datenschutzerklärung
import ContactPageIcon from '@mui/icons-material/ContactPage'; // For Kontakt
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'; // For Shop
import GroupIcon from '@mui/icons-material/Group'; // For Team
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';

const Footer = () => {
    return (
        <footer className="bg-gray-900 py-8 w-full border-t border-gray-700">
            {/* Disclaimer / Copyright */}
            <Typography
                variant="body2"
                className="text-xs text-center text-gray-400 px-4 mb-6"
            >
                Void Roleplay steht nicht in Verbindung zu Mojang, Minecraft oder Microsoft.
            </Typography>
            
            {/* Links Section */}
            <Box className="flex flex-col sm:flex-row justify-center sm:justify-between gap-6 sm:px-8">
                {/* Legal Links */}
                <Box className="flex flex-col sm:flex-row gap-4 sm:gap-6 sm:mr-8">
                    <Link to="/imprint" className="text-xs flex items-center gap-2 text-gray-400 hover:text-white">
                        <GavelIcon className="text-base" /> Impressum
                    </Link>
                    <Link to="/privacy-policy" className="text-xs flex items-center gap-2 text-gray-400 hover:text-white">
                        <PrivacyTipIcon className="text-base" /> Datenschutzerklärung
                    </Link>
                    <Link to="/contact" className="text-xs flex items-center gap-2 text-gray-400 hover:text-white">
                        <ContactPageIcon className="text-base" /> Kontakt
                    </Link>
                </Box>
                
                {/* External Links */}
                <Box className="flex flex-col sm:flex-row gap-4 sm:gap-6 sm:ml-8">
                    <Link to="https://shop.voidroleplay.de" className="text-xs flex items-center gap-2 text-gray-400 hover:text-white">
                        <ShoppingCartIcon className="text-base" /> Shop
                    </Link>
                    <Link to="/team" className="text-xs flex items-center gap-2 text-gray-400 hover:text-white">
                        <GroupIcon className="text-base" /> Team
                    </Link>
                    <Link to="https://discord.gg/void-roleplay" className="text-xs flex items-center gap-2 text-gray-400 hover:text-white">
                        <InterpreterModeIcon className="text-base" /> Discord
                    </Link>
                </Box>
            </Box>
        </footer>
    );
};

export default Footer;
