import { ButtonHTMLAttributes, ReactNode } from "react";

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    children: ReactNode;
}

const Button = ({ className, children, ...rest }: IButton) => {
    return (
        <button
            className={`relative overflow-hidden bg-orange-600 hover:bg-orange-700 text-white font-bold py-4 px-8 focus:outline-none focus:shadow-outline transition duration-300 ${className}`}
            style={{
                clipPath: "polygon(10% 0, 100% 0, 90% 100%, 0 100%)"
            }}
            {...rest}
        >
            {children}
        </button>
    );
};

export default Button;