import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { server } from "../App";
import Logo from "../logo.png";
import { useAuthProvider } from "../hooks/useAuthProvider";
import { ArrowRightAlt } from "@mui/icons-material";

const NavBar = () => {
    const [factions, setFactions] = useState<any[]>([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [leaderboards, setLeaderboards] = useState<any[]>([]);
    const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
    const [isScrolled, setIsScrolled] = useState(false);

    const { currentUser } = useAuthProvider();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const load = async () => {
            let response = await fetch(server + '/faction', {
                method: 'GET',
                mode: 'cors',
            });
            const factionsData = await response.json();
            setFactions(factionsData);

            response = await fetch(server + '/leaderboards', {
                method: 'GET',
                mode: 'cors',
            });
            const leaderboardData = await response.json();
            setLeaderboards(leaderboardData);
        };

        load();
    }, []);

    const toggleDropdown = (dropdownId: number | null) => {
        setDropdownOpen(dropdownOpen === dropdownId ? null : dropdownId);
    };

    return (
        <nav className={`fixed top-0 w-full z-50 transition-colors duration-300 ${isScrolled ? "bg-gray-800 shadow-lg" : "bg-transparent"}`}>
            <div className="max-w-screen-xl mx-auto px-4 py-3 flex items-center justify-between">
                <NavLink to="/" className="flex items-center space-x-3">
                    <img src={Logo} className="h-10" alt="Logo" />
                    <span className="text-2xl font-bold text-white">Void Roleplay</span>
                </NavLink>

                <div className="md:hidden">
                    <button
                        onClick={() => setMenuOpen(!menuOpen)}
                        className="p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-white"
                        aria-controls="navbar-cta"
                        aria-expanded={menuOpen}
                    >
                        <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    </button>
                </div>

                <div className={`fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity ${menuOpen ? "opacity-100" : "opacity-0 pointer-events-none"} md:hidden`} onClick={() => setMenuOpen(false)}></div>

                <div className={`transition-all duration-300 md:flex md:items-center md:space-x-8 ${menuOpen ? "translate-x-0" : "-translate-x-full md:translate-x-0"} md:relative absolute top-0 left-0 w-64 md:w-auto bg-gray-800 md:bg-transparent p-6 md:p-0 md:shadow-none shadow-lg z-50`} id="navbar-cta">
                    <ul className="flex flex-col md:flex-row md:space-x-8">
                        <li>
                            <NavLink to="/" className={({ isActive }) => (isActive ? "block py-2 px-3 text-indigo-400 bg-gray-700 rounded md:bg-transparent" : "block py-2 px-3 hover:text-indigo-300 text-white")}>Start</NavLink>
                        </li>
                        <li className="relative group">
                            <button onClick={() => toggleDropdown(1)} className="block py-2 px-3 text-white hover:text-indigo-300">Hilfe</button>
                            {dropdownOpen === 1 && (
                                <div className="absolute left-0 mt-2 w-40 bg-gray-700 rounded-lg shadow-xl text-gray-200">
                                    <ul className="py-2">
                                        <li>
                                            <Link to="/rules" className="block px-4 py-2 hover:bg-gray-600">Regeln</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact" className="block px-4 py-2 hover:bg-gray-600">Kontakt</Link>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </li>

                        {factions.length > 0 && (
                            <li className="relative group">
                                <button onClick={() => toggleDropdown(2)} className="block py-2 px-3 text-white hover:text-indigo-300">Fraktionen</button>
                                {dropdownOpen === 2 && (
                                    <div className="absolute left-0 mt-2 w-56 bg-gray-700 rounded-lg shadow-xl text-gray-200">
                                        <ul className="py-2">
                                            {factions.map((faction) => (
                                                <li key={faction.id}>
                                                    <Link to={`/factions/${faction.name.toLowerCase()}`} className="block px-4 py-2 hover:bg-gray-600">
                                                        {faction.name}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </li>
                        )}

                        {leaderboards.length > 0 && (
                            <li className="relative group">
                                <button onClick={() => toggleDropdown(3)} className="block py-2 px-3 text-white hover:text-indigo-300">Leaderboards</button>
                                {dropdownOpen === 3 && (
                                    <div className="absolute left-0 mt-2 w-56 bg-gray-700 rounded-lg shadow-xl text-gray-200">
                                        <ul className="py-2">
                                            {leaderboards.map((leaderboard, index) => (
                                                <li key={index}>
                                                    <Link to={`/leaderboard/${leaderboard.url}`} className="block px-4 py-2 hover:bg-gray-600">
                                                        {leaderboard.display}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </li>
                        )}
                    </ul>

                    <div className="mt-4 md:mt-0">
                        {currentUser ? (
                            <Link to="/cp" className="inline-block px-4 py-2 rounded-full bg-indigo-600 hover:bg-indigo-500 transition text-white">Dashboard</Link>
                        ) : (
                            <Link to="/auth/login" className="inline-block px-4 py-2 rounded-full bg-gray-700 hover:bg-gray-600 transition text-white">Login</Link>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
