import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ForumIcon from '@mui/icons-material/Forum';
import { server } from "../../App";
import { formatNumber } from "../../components/api/Utils";
import { CenteredLoader } from "../../utils/Utils";
import Button from "../../components/Button";

const HomeView = () => {
    const [statistics, setStatistics] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [factions, setFactions] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [statsResponse, factionsResponse] = await Promise.all([
                    fetch(`${server}/statistics`),
                    fetch(`${server}/faction`),
                ]);
                const statsData = await statsResponse.json();
                const factionsData = await factionsResponse.json();
                setStatistics(statsData);
                setFactions(factionsData);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-800 via-gray-900 to-black text-gray-100">
            {isLoading ? (
                <div className="flex items-center justify-center h-screen">
                    <CenteredLoader />
                </div>
            ) : (
                <main>
                    <HeroSection navigate={navigate} />
                    {statistics && <StatisticsSection statistics={statistics} />}
                    <FeaturesSection statistics={statistics} />
                    <FactionsSection factions={factions} />
                    <CommunitySection />
                    <AboutUsSection />
                    <TestimonialSection />
                    <GlobalSection />
                </main>
            )}
        </div>
    );
};

const HeroSection = ({ navigate }: { navigate: any }) => (
    <section className="relative flex items-center justify-center min-h-screen bg-backgroundhome bg-cover bg-center">
        <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-70"></div>
        <div className="relative z-10 text-center px-6">
            <h1 className="text-6xl font-extrabold text-white drop-shadow-xl animate__animated animate__fadeIn animate__delay-1s">Willkommen auf Void Roleplay!</h1>
            <p className="mt-4 text-2xl max-w-3xl mx-auto text-gray-200 drop-shadow-lg animate__animated animate__fadeIn animate__delay-2s">
                Entdecke ein einzigartiges Reallife & Roleplay Abenteuer.
            </p>
            <Button 
                onClick={() => navigate('/play')} 
                className="mt-8 px-8 py-4 bg-gradient-to-r from-orange-500 to-orange-600 text-white font-semibold hover:scale-105 hover:shadow-2xl transition-transform shadow-lg">
                Jetzt spielen
            </Button>
        </div>
    </section>
);

const StatisticsSection = ({ statistics }: { statistics: any }) => (
    <section className="py-12 bg-gray-800 text-center">
        <div className="max-w-4xl mx-auto">
            <p className="text-xl font-light text-gray-300">
                Trete unserer lebendigen Minecraft-Community bei und wähle aus <strong className="text-orange-400 text-3xl animate-pulse">{formatNumber(statistics.factionCount)}</strong> spannenden Fraktionen oder werde Teil eines aufstrebenden Unternehmensimperiums mit <strong className="text-orange-400 text-3xl animate-pulse">{formatNumber(statistics.companyCount)}</strong> Unternehmen.
            </p>
        </div>
    </section>
);

const FeaturesSection = ({ statistics }: { statistics: any }) => (
    <section className="py-16 bg-gray-900 text-gray-100">
        <div className="max-w-6xl mx-auto text-center">
            <h2 className="text-4xl font-bold mb-8 text-orange-400">Warum Void Roleplay?</h2>
            <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                <FeatureCard
                    icon={<GroupIcon className="text-5xl text-orange-500" />}
                    title="Aktive Community"
                    description="Schließe dich hunderten von Spielern an und erlebe ein dynamisches und lebendiges Spielerlebnis."
                />
                <FeatureCard
                    icon={<BusinessIcon className="text-5xl text-orange-500" />}
                    title="Unternehmen"
                    description="Gründe und verwalte dein eigenes Unternehmen, kaufe Geschäfte auf, stelle Mitarbeiter an und lass dein Imperium wachsen."
                />
                <FeatureCard
                    icon={<EmojiEventsIcon className="text-5xl text-orange-500" />}
                    title="Fraktionen"
                    description={`Tritt einer von ${statistics && statistics.factionCount} Fraktionen bei und erobere, verteidige oder verbessere die Stadt.`}
                />
            </div>
        </div>
    </section>
);

const FactionsSection = ({ factions }: { factions: any[] }) => (
    <section className="py-16 bg-gray-800 text-center text-gray-100">
        <div className="max-w-6xl mx-auto">
            <h2 className="text-4xl font-bold mb-4 text-orange-400">Fraktionen</h2>
            <p className="text-lg font-light mb-8">Tritt einer Fraktion bei und entfalte dein RP Erlebnis.</p>
            <div className="flex overflow-x-auto space-x-4 py-4 scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800">
                {factions.map((faction) => (
                    <FactionCard
                        key={faction.id}
                        name={faction.name}
                        fullname={faction.fullname}
                        description={faction.description}
                        image={faction.image}
                    />
                ))}
            </div>
        </div>
    </section>
);

const CommunitySection = () => (
    <section className="py-16 bg-gray-800 text-center text-gray-100">
        <div className="max-w-5xl mx-auto">
            <h2 className="text-4xl font-bold mb-8 text-orange-400">Unsere Community</h2>
            <p className="text-lg font-light mb-8">
                Unsere Community ist das Herz von Void Roleplay. Wir bieten eine freundliche, hilfsbereite und aktive Gemeinschaft von Spielern, die alle dasselbe Ziel teilen: Spaß haben und epische Abenteuer erleben.
            </p>
            <div className="flex justify-center space-x-4">
                <CommunityLink
                    url="https://discord.gg/void-roleplay"
                    text="Discord beitreten"
                    icon={<ForumIcon className="text-xl" />}
                    bgColor="bg-blue-500"
                    hoverColor="hover:bg-blue-600"
                />
            </div>
        </div>
    </section>
);

const GlobalSection = () => (
    <section className="py-16 bg-gray-900 text-center text-gray-100">
        <div className="max-w-5xl mx-auto">
            <h2 className="text-4xl font-bold mb-8 text-orange-400">Du und Void Roleplay</h2>
            <p className="text-lg font-light mb-8">
                Void Roleplay lebt von seiner Community und so handeln wir. Du hast eine Idee? Sag Sie uns und wir versuchen diese umzusetzen! Dich stört etwas? Sag uns das und wir ändern es!
            </p>
        </div>
    </section>
);

const AboutUsSection = () => (
    <section className="py-16 bg-gray-800 text-center text-gray-100">
        <div className="max-w-5xl mx-auto">
            <h2 className="text-4xl font-bold mb-8 text-orange-400">Über uns</h2>
            <p className="text-lg font-light mb-8">
            Void Roleplay ist ein in 2023 gegründeter Minecraft Reallife- und Roleplay-Server, der es sich zur Aufgabe gemacht hat, ein einzigartiges und fesselndes Spielerlebnis zu bieten. Unsere Vision ist es, den Spielern ein tiefgründiges und realistisches Abenteuer zu ermöglichen, ohne auf Mods angewiesen zu sein. Wir glauben an die Macht der Kreativität und des freien Rollenspiels und haben daher eine Welt erschaffen, in der jeder die Möglichkeit hat, sein eigenes Schicksal zu gestalten.

Unser Server konzentriert sich darauf, eine dynamische, offene und lebendige Umgebung zu schaffen, in der Spieler in verschiedene Rollen schlüpfen können. Egal, ob du ein Unternehmer, ein Polizist, ein Krimineller oder ein einfacher Bürger bist – bei Void Roleplay ist jeder Spieler ein wichtiger Teil des Ganzen. Unsere Welt ist von einer aktiven Community geprägt, die sich leidenschaftlich in das Geschehen einbringt und ständig neue Geschichten erschafft.

Was uns von anderen Servern unterscheidet, ist unser Fokus auf Innovation und Vielfalt. Wir haben die Grundlage für ein immersives Erlebnis ohne die Notwendigkeit von Mods oder Plugins geschaffen, was bedeutet, dass unser Server auch für neue Spieler leicht zugänglich und gleichzeitig tiefgründig genug für erfahrene Rollenspieler ist. Jeder Bereich des Spiels – sei es das Wirtschaftssystem, die Fraktionen oder die sozialen Interaktionen – wurde so gestaltet, dass er authentisch und herausfordernd ist.

Unser Ziel ist es, die kreativsten, spannendsten und unvergesslichsten Erlebnisse zu schaffen, die Minecraft zu bieten hat. Void Roleplay ist nicht nur ein Spiel, es ist eine Welt, in der du die Kontrolle über deine Entscheidungen und Handlungen hast. Wir bieten eine Plattform für Spieler, um ihre eigenen Geschichten zu schreiben und zu erleben, während sie gleichzeitig in einer der aktivsten und freundlichsten Minecraft-Communities der Welt eingebunden sind.

Wir sind ständig auf der Suche nach neuen Ideen und Inspirationen, um unser Spielerlebnis weiter zu verbessern. Unser Team arbeitet kontinuierlich daran, neue Features zu entwickeln und das Spiel auf dem neuesten Stand zu halten, damit du immer wieder neue Abenteuer entdecken kannst.

Tritt uns bei und werde ein Teil von Void Roleplay – einer Welt, in der du wirklich du selbst sein kannst!            </p>
        </div>
    </section>
);

const TestimonialSection = () => (
    <section className="py-16 bg-gray-900 text-center text-gray-100">
        <div className="max-w-5xl mx-auto">
            <h2 className="text-4xl font-bold mb-8 text-orange-400">Was unsere Spieler sagen</h2>
            <div className="space-y-8">
                <TestimonialCard name="Salty463" text="Das beste Roleplay-Erlebnis, das ich je hatte! Die Community ist unglaublich." />
                <TestimonialCard name="TheoStratmann1" text="Ich liebe die Vielfalt an Fraktionen und Unternehmen. Hier wird nie langweilig!" />
                <TestimonialCard name="Ienji" text="Die Welt von Void Roleplay ist lebendig und fesselnd. Hier finde ich immer neue Freunde." />
            </div>
        </div>
    </section>
);

const TestimonialCard = ({ name, text }: { name: string, text: string }) => (
    <div className="bg-gray-800 p-6 rounded-lg shadow-md">
        <p className="text-gray-300 text-lg font-light mb-4">"{text}"</p>
        <h4 className="text-xl font-semibold text-orange-400">- {name}</h4>
    </div>
);

const FeatureCard = ({ icon, title, description }: { icon: any, title: string, description: string }) => (
    <div className="p-6 bg-gray-800 rounded-lg shadow-lg hover:shadow-xl hover:bg-orange-600 text-gray-100 transition-transform transform hover:scale-105">
        <div className="mb-4 flex justify-center">{icon}</div>
        <h3 className="text-2xl font-semibold mb-2 text-white">{title}</h3>
        <p className="text-sm font-light text-gray-300">{description}</p>
    </div>
);

const FactionCard = ({ name, fullname, description, image }: { name: string, fullname: string, description: string, image: string }) => {
    const navigate = useNavigate();
    return (
        <div className="flex-shrink-0 w-64 p-4 bg-gradient-to-br from-gray-800 to-gray-700 rounded-lg shadow-md hover:shadow-2xl transition-transform transform hover:scale-105">
            <div className={`w-full h-32 bg-cover bg-center rounded-t-lg relative group ${!image ? 'bg-gray-600' : ''}`} style={image ? { backgroundImage: `url(${image})` } : {}}>
                {!image && (
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center text-white text-2xl font-bold">
                        Kein Bild verfügbar
                    </div>
                )}
                <div
                    className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center"
                >
                    <span className="text-white text-2xl font-bold">{name}</span>
                </div>
            </div>
            <div className="p-4">
                <h3 className="text-xl font-semibold mb-2 text-orange-400">{fullname}</h3>
                <p className={`text-sm text-gray-300 mb-4 ${!description ? 'italic' : ''}`}>
                    {description || "Keine Beschreibung verfügbar"}
                </p>
                <div className="flex justify-end mt-4">
                    <Button
                        onClick={() => navigate('/factions/' + name)}
                        className="bg-orange-500 text-white shadow hover:bg-orange-600 transition-all"
                    >
                        Mehr erfahren
                    </Button>
                </div>
            </div>
        </div>
    );
};

const CommunityLink = ({ url, text, icon, bgColor, hoverColor }: { url: string, text: string, icon: any, bgColor: string, hoverColor: string }) => (
    <Link to={url} className={`px-8 py-4 ${bgColor} text-white font-semibold rounded-lg ${hoverColor} transition-all flex items-center space-x-2 shadow-lg hover:shadow-xl`}>
        {icon}
        <span>{text}</span>
    </Link>
);

export default HomeView;
