import { Avatar, Button, Input } from "@mui/joy";
import { Link, useNavigate, useParams } from "react-router-dom"

import AddIcon from '@mui/icons-material/Add';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { useEffect, useState } from "react";
import { server } from "../../App";
import { CenteredLoader } from "../../utils/Utils";

export const FactionsView = () => {

    const [factions, setFactions] = useState<any[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            const response = await fetch(`${server}/faction`, {
                method: 'GET',
                headers: {
                },
                mode: 'cors',
            });
            const companiesData = await response.json();

            setFactions(companiesData);
            setLoading(false);
        }

        load();
    }, [navigate]);


    return (
        <>
            {factions ? (
                <>
                <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900">
                    <div className="mt-10 container mx-auto px-4 md:px-6 py-8">
                        <div className="flex items-center justify-between mb-6 sm:flex-row flex-col">
                            <h1 className="text-3xl font-bold text-gray-800 dark:text-white">Fraktionen ({factions.length})</h1>
                        </div>
                        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                            {!isLoading && !isAddUserModalOpen ? factions.map((faction) => (
                                <>
                                    <Link to={`${faction.name.toLowerCase().replace(" ", "-")}`} className="flex gap-4 items-start rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
                                        <Avatar className="w-14 h-14 border">
                                            <BusinessCenterIcon />
                                        </Avatar>
                                        <div className="grid gap-1">
                                            <h3 className="font-semibold dark:text-gray-200">{faction.fullname}</h3>
                                            <p className="text-sm text-gray-500 dark:text-gray-400">{faction.isActive ? ('Aktiv') : ('Inaktiv')}</p>
                                        </div>
                                    </Link>
                                </>
                            )) : (
                                <>
                                    {!isAddUserModalOpen && (
                                        <CenteredLoader />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
    )
}

function ArrowUpDownIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m21 16-4 4-4-4" />
            <path d="M17 20V4" />
            <path d="m3 8 4-4 4 4" />
            <path d="M7 4v16" />
        </svg>
    )
}


function SearchIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="11" cy="11" r="8" />
            <path d="m21 21-4.3-4.3" />
        </svg>
    )
}
