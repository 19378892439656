import { Route, Routes } from "react-router-dom";
import CPDashboardView from "./CPDashboardView";
import CPNavBar from "../../components/CPNavBar";

const ControlpanelRoutes = () => {
    return (
        <>
        <CPNavBar />
        <Routes>
            <Route path="/" element={<CPDashboardView />} />
        </Routes>
        </>
    )
}

export default ControlpanelRoutes;